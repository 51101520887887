import About from './About';
import Roadmap from './Roadmap';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useMemo } from "react";
import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolflareWebWallet,
    getSolletWallet,
    getSolletExtensionWallet,
    getSolongWallet,
    getLedgerWallet,
    getSafePalWallet,
} from "@solana/wallet-adapter-wallets";

import {
    WalletModalProvider
} from '@solana/wallet-adapter-react-ui';

import "./App.css";
import { DEFAULT_TIMEOUT } from './connection';
import Home from "./Home";

require('@solana/wallet-adapter-react-ui/styles.css');

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
    try {
        const candyMachineId = new anchor.web3.PublicKey(
            process.env.REACT_APP_CANDY_MACHINE_ID!,
        );

        return candyMachineId;
    } catch (e) {
        console.log('Failed to construct CandyMachineId', e);
        return undefined;
    }
};

const candyMachineId = getCandyMachineId();

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
    rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
    // Custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), []);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getSolflareWallet(),
            getSolflareWebWallet(),
            getSolletWallet({ network }),
            getSolletExtensionWallet({ network }),
            getSolongWallet(),
            getLedgerWallet(),
            getSafePalWallet(),
        ],
        []
    );
    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer")!;
        menu.classList.toggle("open-menu");
        console.log("pressed");
      }
    
      return (
        <div>
          <div id="mobileNavContainer" className="mobile-nav">
            <div className="mobile-nav-close-button">
              <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
            </div>
            <ul>
              <li>
                <img className="mobile-nav-logo" src="/img/logo.png" alt="" />
              </li>
              <li>
                <a href="https://www.launchmynft.io/collections/14AyRzijBZVkWnkrGJDPF5KuhtWhzVb5fwRjchUXZfyK/y7tLMrLoD6Oq1mZJlfDe" onClick={toggleMenu}>
                  Minting Page on LMNFT
                </a>
              </li>
              <li>
                <a href="https://magiceden.io/marketplace/FyRpECQ4tL5BtQ3wnS2cm2a8HHtWWwi1VMKwVX7vBXvT" onClick={toggleMenu}>
                 Collection on MagicEden
                </a>
              </li>
              <li>
                <a href="https://hyperspace.xyz/collection/2DTDuZ3ff8DoZ5JDSUYxrzPQPNKxyd5onMs9M56suLy3" onClick={toggleMenu}>
                Collection on Hyperspace
                </a>
              </li>
              <li>
                <a href="https://tensor.trade/trade/2DTDuZ3ff8DoZ5JDSUYxrzPQPNKxyd5onMs9M56suLy3" onClick={toggleMenu}>
                Collection on Tensor
                </a>
              </li>
              <li>
                <a href="https://www.sniper.xyz/collection/qrrq" onClick={toggleMenu}>
                Collection on Sniper
                </a>
              </li>
              <li>
              </li>
              <li>
                <div className="social-icons">
                  <a href="https://twitter.com/QRRQNFT" target="_blank" rel="noreferrer">
                    <img className="nav-social" src="/icons/twitterx.svg" alt="" />
                  </a>
                  <a href="https://linktr.ee/m4220ud" target="_blank" rel="noreferrer">
                    <img className="nav-social" src="/icons/www2.svg" alt="" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="mobile-menu-button" onClick={toggleMenu}>
            <img src="/icons/menu.svg" alt="" />
          </div>
          <nav>
            <div className="nav-container">
              <img className="nav-logo" src="/img/logo.png" alt="" />
              <a className="hide-800" href="https://www.launchmynft.io/collections/14AyRzijBZVkWnkrGJDPF5KuhtWhzVb5fwRjchUXZfyK/y7tLMrLoD6Oq1mZJlfDe">
              Minting Page on LMNFT
              </a>
              <a className="hide-800" href="https://magiceden.io/marketplace/FyRpECQ4tL5BtQ3wnS2cm2a8HHtWWwi1VMKwVX7vBXvT">
              Collection on MagicEden
              </a>
              <a className="hide-800" href="https://hyperspace.xyz/collection/2DTDuZ3ff8DoZ5JDSUYxrzPQPNKxyd5onMs9M56suLy3">
              Collection on Hyperspace
              </a>
              <a className="hide-800" href="https://tensor.trade/trade/2DTDuZ3ff8DoZ5JDSUYxrzPQPNKxyd5onMs9M56suLy3">
              Collection on Tensor
              </a>
              <a className="hide-800" href="https://www.sniper.xyz/collection/qrrq">
              Collection on Sniper
              </a>
              <div className="social-icons hide-50000">
                <a href="https://twitter.com/QRRQNFT" target="_blank" rel="noreferrer">
                  <img className="nav-social" src="/icons/twitterx.svg" alt="" />
                </a>
                <a href="https://linktr.ee/m4220ud" target="_blank" rel="noreferrer">
                  <img className="nav-social" src="/icons/www2.svg" alt="" />
                </a>
              </div>
            </div>
          </nav>
        
           
                      <ThemeProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletModalProvider>
                  <Home/>
                  <About/>
                  <Roadmap/>
                </WalletModalProvider>
              </WalletProvider>
            </ConnectionProvider>
          </ThemeProvider>
    
    
          </div>
      );
    };
    

export default App;
