import styled from "styled-components";

const MainTitle = styled.h1`
  font-size: 66px;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  margin: 0 0 15px 0;
`;

const IconContainer = styled.div`
  display: block;
  margin-top: auto;
  margin-bottom: auto;
`;

const InfoIcon = styled.img`
  height: 22px;
  margin: 0 8px;
  vertical-align: top;
`;

const InfoContainer = styled.div`
  display: inline-flex;
`;

const IconLink = styled.a`
  text-decoration: none;
`;

const ProjectDescription = styled.p`
  font-size: 17px;
  font-weight: 600;
  line-height: 40px;
  font-family: 'Montserrat', sans-serif;
  color: black;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 200%;
  max-width: 200%;
  text-align: left;
  margin: 0px;
`;

const RightContainer = styled.div`
  width: 200%;
  max-width: 200%;
  text-align: left;
  margin: 40px;

`;

const Image = styled.img`
  height: 500px;
  width: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 40px 5px rgba(0,0,0,0.5);

`;

const Link = styled("a")`
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    font-size: 1 rem;
    margin: 0rem;
    cursor: pointer;
    color: initial;
    text-decoration: underline;
    color: black;
    text-align: right;
`;

const ShimmerTitle = styled.h2`
  margin: 20px auto;
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite alternate;
  color: var(--main-text-color);
  @keyframes glow {
    from {
      text-shadow: 0 0 20px var(--main-text-color);
    }
    to {
      text-shadow: 0 0 30px var(--title-text-color), 0 0 10px var(--title-text-color);
    }
  }
`;

const ShimmerTitle2 = styled.h1`
  margin: 20px auto;
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite alternate;
  color: var(--main-text-color);
  @keyframes glow {
    from {
      text-shadow: 0 0 20px var(--main-text-color);
    }
    to {
      text-shadow: 0 0 30px var(--title-text-color), 0 0 10px var(--title-text-color);
    }
  }
`;

const Home = () => {
    return (
        <main>
             <MainContainer className="maincontainer">
               <LeftContainer className="leftcontainer">  
               <ShimmerTitle2><ul>"It's a collection on Solana"</ul></ShimmerTitle2> 
               <ShimmerTitle2><ul>"Mint your QRRQ for 0.02 SOL"</ul></ShimmerTitle2>   
               <br/>
                            <div><Image className="nft-image"
                                src="sample.gif"
                                alt="Sample"/></div>
                            <br/>
            </LeftContainer>
            <RightContainer className="rightcontainer">
            <MainTitle className="maintitle">QR RQ NFT COLLECTION</MainTitle>
                <InfoContainer>
            <IconContainer>
            <IconLink className="infoIcon" href="https://twitter.com/M4220UD" target="__blank"> <InfoIcon src="twitterx.svg" /> </IconLink>
              <IconLink className="infoIcon" href="https://linktr.ee/m4220ud" target="__blank"> <InfoIcon src="www2.svg" /> </IconLink>
            </IconContainer>
          </InfoContainer>
          <ProjectDescription>IT'S A COLLECTION OF 1024 NFTS; EACH OF THEM IS A QR CODE. WHAT'S BEHIND THEM?! WE'LL SEE!</ProjectDescription>
          <ProjectDescription>"I've allocated $1000 prize to the minters of my collection.</ProjectDescription>
          <ProjectDescription>1st winner $450 🤩</ProjectDescription>
          <ProjectDescription>2nd winner $350 🤠</ProjectDescription>
          <ProjectDescription>And last one who mint the last NFT will receive $200 😊</ProjectDescription>
          <ProjectDescription>Don't get it wrong! That's just a bonus. I know people like money. I like it too. But the purpose of this collection is not giving you money. You can read more about the collection   <Link href="/#roadmap">
                here
            </Link>."</ProjectDescription>
                             
                             
                             
          
                    <ShimmerTitle> Click here to see the minting page: </ShimmerTitle> <ShimmerTitle><a href="https://www.launchmynft.io/collections/14AyRzijBZVkWnkrGJDPF5KuhtWhzVb5fwRjchUXZfyK/y7tLMrLoD6Oq1mZJlfDe">
                  Minting Page on LMNFT </a> </ShimmerTitle> 
                  <ShimmerTitle> Click here to see the collection on MagicEden: </ShimmerTitle>  <ShimmerTitle><a href="https://magiceden.io/marketplace/FyRpECQ4tL5BtQ3wnS2cm2a8HHtWWwi1VMKwVX7vBXvT">
                  Collection on MagicEden </a> </ShimmerTitle> 
                     
                    </RightContainer>
                </MainContainer>

        </main>
    );
};

export default Home;
