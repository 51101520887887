import styled from "styled-components";

const Container = styled("div")`
position: relative;
margin-top: 40px;
margin-bottom: 40px;
margin-left: auto;
margin-right: auto;
max-width: 900px;
font-weight: 400;
font-size: 17px;
`

const Card = styled("div")`
align-items: center;
border-radius: var(--card-border-radius);
padding: 40px;
background: var(--card-background-color);
box-shadow: var(--card-shadow);
`
const ListItem = styled("li")`
    margin: 5px;
`

const Roadmap = () => {
    return (
        <Container id="roadmap">
                  <Card>
                  <h1>QR RQ Collection Roadmap</h1>
            <ul style={{padding: "0 10%"}}>
            <ListItem>
                <h4>No Roadmap!</h4> 
                 There is no exciting roadmap! I think it was August 2021 that I started to create this collection. I have tried so much to promote this collection. I have spent so much time and money for this collection because selling this collection out is a personal dream for me. Honestly, no one helped me with this collection. I think people just trust famous collections with huge promotions and ads. Money matters! Everyone just want to know how much they'll earn with minting the NFT or by promoting it. One day, in the first days of 2023, I stopped trying like that. I haven't given up yet and I won't. Sometimes, you just need to do some things in a different way. I just don't want to promise anything that I can't do it. Now I give time to the collection to be sold. I try to introduce this collection to anyone but I don't expect anyone to mint this. I don't push you to mint. If you think this collection worth minting, so mint it. I want to know more people, learn more, and I may sell this collection too. That's it.
                </ListItem>
                <hr />
            <ListItem>
            <h4>Again, An Explanation</h4> 
                I have another friendly explanation about the collection. As I said it's totally an independent NFT project. This project has no team or sponsor. You are the real team and sponsor of this project. I know there is a risk of losing some SOL and getting just 1 or some NFTs but the idea behind this collection is having a QR code with a random quote. It can be a gift to a friend too or something you can hold in your wallet. I know that you mint an NFT to trade it, then I do my best to make this collection profitable. You know that there are so many rug pull & hyped project in NFT community. You can pay a lot of money to promote a project and it will be sold out easily. Then, no one will response about the project. A big promotion is just a guarantee for the creators of a project, not for the owners. There is no guarantee for a successful project except the owners. And I think that peer to peer promotion is the best way to promote a project. So, I want to ask for your help too. Because I know the fans and the owners of a project can make it successful. If you like this project and you want to help an independent project, please just share it with the others. I don't ask it only for my project, please support independent artists and projects more and more. Thanks in advance.
                </ListItem>
                <hr />
             
                  </ul>
                  
             </Card>

        </Container>
    )
}

export default Roadmap;